<template>
  <div
    class="views_shopping_mail_modules_common_navigation_bar bbox d-flex align-center"
  >
    <template
      v-for="(item,index) in list"
    >
      <div
        :key="index"
        class="unit d-flex align-center cp un_sel"
        :class="{active: page_type === item.page_type}"
        @click="changeType(item)"

      >
        <div class="name">{{item.name}}</div>
        <img
          :src="require(`@/assets/images/shopping_mail/navigation_bar/${item.icon}.png`)"
          alt=""
          class="icon"
          v-if="item.icon"
        >
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    page_type: {
      type: String,
      default: null,
    },
  },
  data(){
    return {
      list: [
        {name: '精选', page_type: 'featured',},
        // {name: 'Vip专区', icon: 'vip'},
        {name: '付费课程', page_type: 'teaching_package'},
        {name: '任务榜单', page_type: 'task'},
      ],
    }
  },
  methods: {
    changeType(item){
      const {page_type} = item;
      if(item){
        this.$api.shopping_mail.setTopNav({type: 'page', page_type});
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.views_shopping_mail_modules_common_navigation_bar{
  border-bottom: 2px solid #f4f4f4;
  .unit{
    line-height  : 37px;
    font-size    : 14px;
    border-bottom: 3px solid transparent;
    margin-right : 50px;
    &.active{
      border-bottom: 3px solid $main_blue;
      color        : $main_blue;
    }
  }
}
</style>
